<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-select v-model="product.product_subcategory_id" :items="loadProductSubcategories" item-text="prod_sc_nome" item-value="prod_sc_id" label="Selecione uma Subcategoria..." :rules="rules.productSubcategoryRules" required outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-select v-model="product.product_brand_id" :items="loadProductBrands" item-text="prod_b_nome" item-value="prod_b_id" label="Selecione uma Marca..." :rules="rules.productModelRules" required outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                    <v-select v-model="product.product_measure_id" :items="loadProductMeasures" item-text="prod_me_nome" item-value="prod_me_id" label="Selecione uma Medida..." :rules="rules.productMeasureRules" required outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="product.prod_nome" :rules="rules.nomeRules" label="Nome" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="product.prod_variacao" label="Variação" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <v-text-field v-model="product.prod_codigo" label="Códígo do Produto" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <v-text-field v-model="product.prod_sku" label="SKU" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <v-text-field v-model="product.prod_cod_item" label="Códígo Item" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <v-text-field v-model="product.prod_referencia" label="Referência" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="product.prod_estoque" label="Estoque" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <div class="input-v-money">
                        <label for="">Valor</label>
                        <money v-model="product.prod_valor_b2c" v-bind="money"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <div class="input-v-money">
                        <label for="">Percentual de Desconto ( % )</label>
                        <money v-model="product.prod_perc_desconto" v-bind="money" @blur.native="calcValorPromocao"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <div class="input-v-money">
                        <label for="">Valor em Promoção</label>
                        <money v-model="product.prod_valor_promocao" v-bind="money" :disabled="isPercDiscount"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <v-select v-model="product.prod_potencia" :items="potencia" item-text="name" item-value="value" label="Potência" outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <v-select v-model="product.prod_formato" :items="formato" item-text="name" item-value="value" label="Formato" outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <div class="input-v-money">
                        <label for="">Peso (Kg)</label>
                        <money v-model="product.prod_peso" v-bind="peso"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <div class="input-v-money">
                        <label for="">Altura (CM)</label>
                        <money v-model="product.prod_altura" v-bind="medida"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <div class="input-v-money">
                        <label for="">Largura (CM)</label>
                        <money v-model="product.prod_largura" v-bind="medida"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <div class="input-v-money">
                        <label for="">Comprimento (CM)</label>
                        <money v-model="product.prod_comprimento" v-bind="medida"></money>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <v-text-field v-model="product.prod_tamanho" label="Tamanho" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="3">
                    <v-text-field v-model="product.prod_videos" label="Vídeo" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea v-model="product.prod_breve_descricao" :counter="600" name="breve_descricao" label="Breve descrição" outlined></v-textarea>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-textarea v-model="product.prod_meta_descricao" :rules="rules.metaDescricaoRules" :counter="180" name="meta_descricao" label="Meta Descrição" outlined required></v-textarea>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-textarea v-model="product.prod_keywords" :rules="rules.keywordsRules" :counter="180" name="keywords" label="Palavras Chaves" outlined required></v-textarea>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                        <div class="menububble" :class="{ 'is-active': menu.isActive }" :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`">

                            <v-flex xs6 p-5>
                                <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                                    <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu" />
                                    <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
                                        <v-icon>cancel</v-icon>
                                    </button>
                                </form>
                            </v-flex>

                            <v-flex xs12 sm6 class="py-2">
                                <v-btn-toggle v-model="toggle_multiple" multiple>
                                    <v-btn text :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })">
                                        H2
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })">
                                        H3
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.heading({ level: 4 }) }" @click="commands.heading({ level: 4 })">
                                        H4
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                                        <v-icon>format_bold</v-icon>
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
                                        <v-icon>format_italic</v-icon>
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
                                        <v-icon>format_underlined</v-icon>
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.code() }" @click="commands.code">
                                        <v-icon>code</v-icon>
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
                                        <v-icon>format_textdirection_l_to_r</v-icon>
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
                                        <v-icon>format_list_bulleted</v-icon>
                                    </v-btn>
                                    <v-btn text :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
                                        <v-icon>format_list_numbered</v-icon>
                                    </v-btn>
                                    <v-btn text @click="commands.horizontal_rule">
                                        <v-icon>space_bar</v-icon>
                                    </v-btn>
                                    <v-btn text @click="commands.undo">
                                        <v-icon>undo</v-icon>
                                    </v-btn>
                                    <v-btn text @click="commands.redo">
                                        <v-icon>redo</v-icon>
                                    </v-btn>

                                    <v-btn class="menububble__button" @click="showLinkMenu(getMarkAttrs('link'))" :class="{ 'is-active': isActive.link() }">
                                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                                        <v-icon name="link">link</v-icon>
                                    </v-btn>

                                </v-btn-toggle>
                            </v-flex>

                        </div>
                    </editor-menu-bubble>

                    <editor-content class="editor__content" :editor="editor" outline />
                </v-col>

                <!--<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" :max-tags="20" />
                </v-col>-->

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="2">
                    <v-switch color="lime" v-model="product.prod_show_site" label="Mostrar no Site"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="2">
                    <v-switch color="lime" v-model="product.prod_destaque_home" label="Destaque Home"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="2">
                    <v-switch color="lime" v-model="product.prod_show_preco" label="Mostrar Preço"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="2">
                    <v-switch color="lime" v-model="product.prod_em_promocao" label="Produto em Promoção"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="2">
                    <v-switch color="lime" v-model="product.prod_orcamento" label="Solicitar Orçamento"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="2">
                    <v-switch color="lime" v-model="product.prod_status" label="Status"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="2">
                    <v-switch color="lime" v-model="product.prod_destaque_carrossel" label="Destaque Carrossel"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                    <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                        <v-icon class="mdi mdi-check"></v-icon>{{btnLabel}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    Money
} from 'v-money'
import VueTagsInput from '@johmun/vue-tags-input';
import Icon from '../../../../components/Icon'
import {
    URL_BASE
} from '../../../../config/configs'
import {
    Editor,
    EditorContent,
    EditorMenuBubble,
} from 'tiptap'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Underline,
    History,
} from 'tiptap-extensions'

export default {
    name: "FormProductComponent",
    props: {
        product: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_id: '',
                    product_subcategory_id: '',
                    product_brand_id: '',
                    product_measure_id: '',
                    prod_codigo: '',
                    prod_sku: '',
                    prod_cod_item: '',
                    prod_referencia: '',
                    prod_nome: '',
                    prod_variacao: '',
                    prod_slug: '',
                    prod_desc: '',
                    prod_breve_descricao: '',
                    prod_videos: '',
                    prod_valor_b2c: 0.00,
                    prod_valor_promocao: 0.00,
                    prod_perc_desconto: 0.00,
                    prod_estoque: 0,
                    prod_peso: 0.00,
                    prod_formato: 0.00,
                    prod_altura: 0.00,
                    prod_largura: 0.00,
                    prod_comprimento: 0.00,
                    prod_volume: 0.00,
                    prod_diametro: 0.00,
                    prod_tamanho: 0,
                    prod_potencia: '',
                    prod_foto_destaque: '',
                    prod_opcoes: '',
                    prod_tag: '',
                    prod_visualizado: 0,
                    prod_show_site: true,
                    prod_show_preco: true,
                    prod_destaque_home: false,
                    prod_destaque: false,
                    prod_em_promocao: false,
                    prod_orcamento: false,
                    prod_novo: false,
                    prod_mais_vendido: false,
                    prod_keywords: '',
                    prod_meta_descricao: '',
                    prod_status: true,
                    prod_destaque_carrossel: false,

                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    created() {
        this.$store.dispatch('loadProductSubcategories');
        this.$store.dispatch('loadProductBrands');
        this.$store.dispatch('loadProductMeasures');

    },
    computed: {
        loadProductSubcategories() {
            if (this.$store.state.productsubcategories.items.data == null)
                return []

            return this.$store.state.productsubcategories.items.data;
        },
        loadProductBrands() {
            if (this.$store.state.productbrands.items.data == null)
                return []

            return this.$store.state.productbrands.items.data;
        },
        loadProductMeasures() {
            if (this.$store.state.productmeasures.items.data == null)
                return []

            return this.$store.state.productmeasures.items.data;
        },
        isPercDiscount() {
            if (this.product.prod_perc_desconto != 0.00) {
                return true
            }

            return false
        }
    },
    watch: {
        product() {
            this.setEditorProduct();

            //if (this.product.prod_tag !== undefined || this.product.prod_tag !== ""){            
            //    this.tags = this.product.prod_tag.split(',');
            //}
        }
    },

    data() {
        return {
            prod_tag: '',
            tag: '',
            tags: [],
            formato: [{
                    name: 'Caixa/Pacote',
                    value: 1,
                },
                {
                    name: 'Rolo/Prisma',
                    value: 2,
                },
                {
                    name: 'Envelope',
                    value: 3,
                }
            ],
            potencia: [{
                    name: 'Não se aplica',
                    value: '',
                },
                {
                    name: '110',
                    value: '110',
                },
                {
                    name: '220',
                    value: '220',
                },
                {
                    name: '110-220',
                    value: '110-220',
                }
            ],
            valid: true,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            peso: {
                decimal: '.',
                thousands: '',
                prefix: '',
                suffix: '',
                precision: 3,
                masked: false /* doesn't work with directive */
            },
            medida: {
                decimal: '.',
                thousands: '',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            toggle_multiple: [],
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({
                        levels: [1, 2, 3, 4]
                    }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Underline(),
                    new History(),

                ],
                content: '',
            }),
            linkUrl: null,
            linkMenuIsActive: false,
            rules: {
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 150) || 'Nome precisa ter até 200 Caracteres'
                ],
                productSubcategoryRules: [
                    v => !!v || 'Campo Subcategoria é obrigatório',
                ],
                productBrandRules: [
                    v => !!v || 'Campo Modelo é obrigatório',
                ],
                productMeasureRules: [
                    v => !!v || 'Campo Medida é obrigatório',
                ],
                metaDescricaoRules: [
                    v => !!v || 'Campo Meta descrição é obrigatório',
                    v => (v && v.length <= 180) || 'Meta Descrição deve ter até 180 caracteres'
                ],
                keywordsRules: [
                    v => !!v || 'Campo Palavras Chaves é obrigatório',
                    v => (v && v.length <= 180) || 'Palavras Chaves deve ter até 180 caracteres'
                ],
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    beforeDestroy() {
        // Always destroy your editor instance when it's no longer needed
        this.editor.destroy()
    },
    methods: {
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
        hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(command, url) {
            command({
                href: url
            })
            this.hideLinkMenu()
        },
        calcValorPromocao() {
            if (this.product.prod_perc_desconto != 0.00) {
                this.product.prod_valor_promocao = (this.product.prod_valor_b2c - (this.product.prod_valor_b2c * (this.product.prod_perc_desconto / 100)))
            }else{
                this.product.prod_valor_promocao = 0.00
            }
        },
        setEditorProduct() {
            if (this.product.prod_desc !== undefined)
                this.editor.setContent(this.product.prod_desc);
        },
        log(msg) {
            console.log(msg)
        },
        onSubmit() {

            if (this.$refs.form.validate()) {

                if (this.product.prod_em_promocao && this.product.prod_valor_promocao === 0.00) {
                    this.$swal({
                        position: "center",
                        icon: "warning",
                        title: 'Atenção',
                        text: 'Valor em promoção é requerido e não pode ser 0.00',
                        showConfirmButton: true,
                        timer: 6000
                    });
                    return;
                }
                const action = this.update ? 'updateProduct' : 'storeProduct';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                this.prod_tag = '';

                for (let i in this.tags) {
                    if (i == this.tags.length - 1) {
                        this.prod_tag += this.tags[i].text;
                    } else {
                        this.prod_tag += this.tags[i].text + ', ';
                    }
                }

                this.calculaVolume(this.product.prod_altura, this.product.prod_largura, this.product.prod_comprimento)

                this.product.prod_desc = this.editor.getHTML();
                this.product.prod_tag = this.prod_tag;

                this.$store.dispatch(action, this.product)
                    .then(() => {
                        if (!this.update) {
                            this.reset();
                        } else {
                            this.$router.push({
                                name: 'products'
                            });
                        }
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        console.log(error)
                        //this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
        reset() {
            this.$refs.form.reset();
            this.editor.setContent('');
            this.tags = [];
            this.product.prod_valor_b2c = 0.00;
            this.product.prod_peso = 0.00;
            this.product.prod_altura = 0.00;
            this.product.prod_largura = 0.00;
            this.product.prod_comprimento = 0.00;

        },
        showImagePrompt(command) {
            const src = prompt('Entre com a URL da magem')
            if (src !== null) {
                command({
                    src
                })
            }
        },
        calculaVolume(a, l, c) {
            this.product.prod_volume = a * l * c;
        }
    },
    components: {
        EditorContent,
        EditorMenuBubble,
        Icon,
        VueTagsInput,
        Money
    },
};
</script>

<style lang="scss">
.input-v-money {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    border-radius: 4px;
    padding: 6px 12px;
}

.input-v-money>label {
    color: rgba(255, 255, 255, 0.3);
    font-size: .8rem;
}

.input-v-money>input {
    color: #FFF;
}

:focus {
    outline: -webkit-focus-ring-color auto 0px;
}

.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}

.editor__content {
    border: 1px solid #6f6e6e;
    padding: 0.5rem;
}

.ProseMirror {
    border: none;
    outline: 0;
}

.ProseMirror,
.ProseMirror-focused,
.ProseMirror-focused:focus,
textarea:focus,
input:focus,
select:focus {
    border: none !important;
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/* TAGS */
.vue-tags-input {
    max-width: 100% !important;
    background-color: #303030 !important;
}

.ti-input {
    border: 1px solid #6f6e6e !important;
}

.ti-tag {
    background-color: #99ca5f !important;
    color: #060606;
}

.ti-tag span {
    line-height: .85em;
    font-weight: 600;
}
</style>
